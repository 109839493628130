/* Sass Variable */
$dmsans: 'DM Sans', sans-serif;
/* Sass Variable */


/*====================
1.Brand Primary Variables
====================*/

$brand-primary: #255A61;
$brand-secondary: #ECA264;
$brand-terciary: #291203;
$brand-white: #FFFFFF;
$brand-black: #000000;
$brand-transparent: transparent;


/*====================
2.Primary Variables
====================*/
$primary_dark: #173D42;
$primary_90: #2D3136;
$primary_80: #5C6B74;
$primary_60: #73848D;
$primary_40: #8B9DA7;
$primary_20: #A4B6C0;
$primary_10: #BFD0DA;


/*====================
3.Secondary Variables
====================*/
$secondary_80: #F0B583;
$secondary_60: #F4C7A2;
$secondary_40: #F7DAC1;
$secondary_20: #FBECE0;
$secondary_10: #FEF6F0;


/*====================
4.Neutral Variables
====================*/
$neutral_80: #727981;
$neutral_60: #929196;
$neutral_40: #A0A7AF;
$neutral_20: #D1D6DC;
$neutral_10: #F8F8F8;


/*====================
5.Feedback Variables
====================*/
$success: #98CF6D;
$success_10: #EDF5E7;
$warning: #EF925E;
$warning_10: #FDF2EC;
$error: #FE7F7F;
$error_dark: #CB6666;
$error_10: #FFECEC;


/*====================
6.Social Media Variables
====================*/
$facebook: #3B5998;
$messenger: #0084FF;
$twitter: #00A2F9;
$whatsapp: #75CF6C;
$youtube: #FF0000;
$pinterest: #A5221F;
$Google: #EA4335;
$linkedin: #0E76A8;
$instagram: radial-gradient(113.07% 113.07% at 10.23% 113.07%, #FA8F21 0%, #F0723D 21.88%, #EE6D42 43.75%, #E24964 60.94%, #D02F83 81.25%, #8B439E 100%);


/*====================
6.Extra Variables
====================*/
$toggle-disable: #f9fbfc;
$netutral:#C4C4C4;
$danger: #dc3545;
