/* You can add global styles to this file, and also import other style files */

@import 'libs/theme/scss/global_mixin.scss';
@import 'libs/theme/scss/global_variables';
@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';


.swiper {
  .swiper-pagination-bullet {
    background-color: $brand-white;
  }

  .swiper-slide {
    img {
      object-fit: unset !important;
      object-position: unset !important;
    }
  }
}


