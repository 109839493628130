@media (max-width: 1700px) {
  .fs-64 {
    font-size: 60px;
  }
  .fs-48 {
    font-size: 40px;
  }
  .fs-32 {
    font-size: 27px;
  }
  .fs-24 {
    font-size: 22px;
  }
  .fs-18 {
    font-size: 17px;
  }
}

@media (max-width: 1366px) {
  .fs-64 {
    font-size: 55px;
  }
  .fs-48 {
    font-size: 35px;
  }
  .fs-32 {
    font-size: 25px;
  }
  .fs-24 {
    font-size: 20px;
  }
  .fs-18 {
    font-size: 16px;
  }
  .fs-16 {
    font-size: 15px;
  }
  .fs-20 {
    font-size: 19px;
  }
}


@media (max-width: 1199px) {
  .fs-64 {
    font-size: 50px;
  }
  .fs-48 {
    font-size: 28px;
  }
  .fs-32 {
    font-size: 20px;
  }
  .fs-24 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .fs-16 {
    font-size: 14px;
  }
  .fs-20 {
    font-size: 18px;
  }

  .store-status-details {
    height: 85px;

    .price {
      margin-bottom: 0;
    }
  }

  .custom-table {
    .dataTables_wrapper {
      .dataTables_filter {
        width: 30%;
      }
    }
  }
}

@media (max-width: 960px) {
  .seller-setup {
    .seller-tab-details {
      padding-top: 75px;

      &.product-add-screen {
        .seller-tab-left-box {
          padding-top: 40px;
        }

        .seller-tab-right-box {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .fs-64 {
    font-size: 45px;
  }
  .fs-48 {
    font-size: 25px;
  }

  .custom-table {
    .dataTables_wrapper {
      .dataTables_filter {
        width: 45%;
      }
    }
  }

  .store-status-details {
    height: 120px;
  }
}


@media (max-height: 920px) {
  .seller-setup {
    .seller-tab-details {
      .seller-tab-left-box {
        .img-wrapper {
          width: 70%;
        }
      }

    }
  }
}

@media (max-height: 820px) {
  .seller-setup {
    .seller-tab-details {
      .seller-tab-right-box {
        height: auto;
      }
    }
  }
}

@media (max-width: 820px) {
  .seller-setup {
    .seller-tab-details {
      //padding-top: 130px;

      .seller-tab-right-box {
        padding: 80px 60px;
      }

      &.product-add-screen {
        padding-top: 60px;
      }
    }
  }
}
@media (max-width: 800px) {
  .seller-setup {
    .seller-tab-details {
      padding-top: 130px;
    }
  }
}


@media (max-width: 767px) {
  .header {
    background-color: $secondary_20;
    border-radius: 0 0 16px 16px;

    .header-right-menu {
      justify-content: flex-end;
      padding-right: 15px;
    }
  }

  .side-menu {
    bottom: 0;
    width: 100%;
    right: 0;
    height: auto;
    padding: 0 15px;
    background-color: $brand_white;
    box-shadow: 0 0 4px 0 rgba($brand-black, 0.2);

    ul {
      display: flex;
      overflow-y: auto;
      justify-content: center;
      align-items: center;

      li {
        padding: 0;
        max-width: 16.667%;
        width: 100%;
        a {
          justify-content: center;
          span {
            display: none;
          }
        }

        &.active {
          a {
            background-color: $brand-transparent;
          }
        }
      }
    }
  }

  .custom-model {
    &.color-class {
      .modal-content {
        padding: 35px 45px;
      }
    }
  }

  .seller-setup {
    .seller-tab-details {
      padding-top: 80px;
      padding-right: 15px;

      .row {
        height: auto;
      }

      .seller-tab-left-box {
        background-color: $brand_transparent;
        padding: 0 20px;

        .img-wrapper {
          display: none;
        }
      }

      .seller-tab-right-box {
        padding: 0 10px 80px;
        overflow: unset;
        height: auto;

        .footer-details {
          left: 0;
          border-top: 8px solid $secondary_10;

          &:before {
            position: absolute;
            content: '';
            height: 8px;
            left: 0;
            top: -8px;
            background-color: $brand-secondary;
            z-index: 55;
          }
        }
      }

      &.product-add-screen {
        .seller-tab-left-box {
          padding-top: 20px;
        }

        .seller-tab-right-box {
          padding-top: 0;
        }
      }
    }
  }

  .store-review {
    .title {
      margin-bottom: 20px;
    }
  }

  .auth-component {
    .bg-right--img {
      top: auto;
      bottom: 0;
      height: auto;
      left: 0;
      width: 100%;
      min-height: auto;
    }
  }
}


@media (max-width: 576px) {
  .custom-table {
    .dataTables_wrapper {
      .dataTables_filter {
        width: 100%;
      }
    }
  }

  .header {
    padding: 0;
    box-shadow: none;
  }


  .store-status-details {
    height: 95px;
  }

  .custom-model {
    &.color-class {
      .modal-content {
        padding: 20px;
      }
    }
  }

  .action-model {
    .modal-content {
      .modal-body {
        padding: 0 50px 30px;
      }
    }

  }

  .store-review {
    .title {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 420px) {
  .side-menu {
    ul {
      justify-content: start;
    }
  }

}


@media (max-width: 360px) {


  .action-model {
    .modal-content {
      .modal-body {
        padding: 0 30px 20px;
      }
    }
  }

  .store-review {
    .store-review-bg {
      .btn-action {
        max-width: 100%;
      }
    }
  }
}
